.simple-table {
  width: 100%;
  margin-bottom: 1rem;

  tr td {
    max-width: 33.3%;
    border: 1px solid $gray-800;
    padding: 12px 22px 16px 22px;
    white-space: pre-wrap;

    &:nth-child(1) {
      width: 25%;
    }
  }
}

.artifacts-document-text {
  cursor: pointer;
  color: $light-blue;
  font-size: 13px;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
}

.help-document-content {
  display: flex;
  flex-wrap: wrap;

  &__list {
    &:first-child {
      margin-right: 60px;
    }

    .artifacts-document-text {
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0rem;
      }
    }
  }  
}

@media screen and (max-width: 472px) {
  .help-document-content { 
    &__list {
      &:first-child {
        .artifacts-document-text {    
          &:last-child {
            margin-bottom: 1rem;
          }
        }
      }  
      
    }  
  }
} 

.list-default {
  list-style: disc;
  padding-left: 40px;
}

.help-introduction-content {
  color: $gray;
  font-size: 14px;

  .logo-text {
    font-size: 1rem;
    font-weight: 600;
    color: #495057;
    line-height: 25px;
    text-transform: uppercase;
  }
}

.help-image-container {
  position: relative;
  width: 100%;
  cursor: pointer;
  margin-bottom: 1rem;

  &__image {
    width: 100%;
  }

  &:hover {    
    &::after {
      content: url('../../../images/help-screen/ic_expand@2x.svg');
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }  
}

.modal-xl-custom {
  max-width: 1600px;
  position: relative;
  padding: 1rem;

  .modal-content {
    border: none;
    border-radius: 5px;
  }

  .modal-close-icon {
    position: absolute;
    right: 0;
    top: -40px;
    font-size: 1.6rem;
    color: $white;
  }
}

.modal-help-image {
  width: 100%;

  img {
    width: 100%;
  }
}