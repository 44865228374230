.pass-question-container {
  grid-column-gap: 10px;

  ol {
    li {
      margin-bottom: 5px;
    }
  }

  .error-answer {
    color: red;
  }
}

.checkbox-training {
  display: flex;

  p {
    font-weight: 500;
    color: $gray;
  }
}

.dropdown-text {
  padding: 8px;
  color: $gray;
  font-size: 12px;
  cursor: pointer;
  transition: .2s ease-in-out background-color;

  &:hover {
    background-color: #f7faff;
  }
}

.filter-item {
  transition: color .2 ease;

  &:hover {
    color: $light-blue;
  }
}

.resourse {
  font-size: 13px;
  font-weight: 500;
  color: $light-blue;
  cursor: pointer;
}

.question-container {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: auto;
  column-gap: 10px;

  .question-item {
    &__text {
      position: relative;
      cursor: pointer;
      padding-right: 2rem;

      i {
        color: $white-300;
        position: absolute;
        right: 15px;
        top: 2px;

      }

      .downArrow {
        transform: rotate(180deg);
      }
    }
  }
}

@media (max-width: 800px) {
  .question-container {
    grid-template-columns: minmax(0, 1fr);
    row-gap: 10px;
  }
}

.completion-section {
  &__title {
    font-size: .8125rem;
    font-weight: 800;
    margin-bottom: 1.3rem;
  }

  .completion-list {
    padding-left: 0;
    font-size: .8125rem;
    font-weight: 500;
    color: rgba(73, 80, 87, 0.7);
    list-style: none;

    hr {
      margin: 1.25rem 0 1rem 0;
    }

    .completion-item-container {
      display: flex;
      justify-content: space-between;

      span {
        color: $light-blue;
        cursor: pointer;
      }
    }
  }
}

.passed-answer {
  position: relative;

  i {
    position: absolute;
    font-size: 1.4rem;
    color: $blue;
    top: 50%;
    left: -40px;
    transform: translateY(-50%);
  }
}

.pass-answer {
  position: relative;
  display: flex;

  .checkbox-answer {
    position: absolute;
    left: -38px;
  }
}

.add-btn {
  display: flex;
  align-items: center;
  font-weight: 300;
  color: $white;
  outline: none;
  padding: 6px 12px;
  border-radius: 8px;
  font-size: 13px;
  background-color: $blue;
  border: solid 1px $blue;
  transition: .3s ease;

  &:hover {
    background-color: $blue-hover;
    border: solid 1px $blue-hover;
  }

  i {
    margin-right: 6px;
    font-size: 18px;
  }
}

.question-item {
  p {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 4px;
  }

  .trash-icon {
    align-self: center;
    font-size: 1rem;
    margin: 0 .5rem;
  }

  .icon-disabled {
    opacity: .5;
  }
}

.answer-item {
  .custom-input {
    width: 90%;
    position: relative;

    &__item {
      padding: 0.47rem 2rem 0.47rem 0.75rem;
    }

    .custom-checkbox-answer {
      position: absolute;
      top: 50%;
      right: 5px;
      transform: translateY(-50%);
    }
  }

  &__add {
    width: 90%;
    padding: 0.47rem 2rem 0.47rem 0.75rem;
    border-radius: 4px;
    border: dashed 1px $white-100;
    margin-bottom: 1rem;
    color: $white-100;
    cursor: pointer;
  }
}