.notification-list {
  list-style: none;
  padding-left: 0;

  .notification-item {

    h6 {
      font-size: 14px;
      line-height: 18px;
      font-weight: 700;
    }

    &__description {
      font-size: 13px;
    }

    &__footer {
      display: flex;
      justify-content: space-between;
      font-size: 12px;

      span {
        margin-right: 10px;
        cursor: pointer;

        &.dismiss {
          color: red;
        }

        &.see {
          color: $light-blue;
        }
      }
    }
  }
}

.notification-header {
  display: flex;
  justify-content: space-between;

  .notification-title {
    font-weight: 600;
  }

  span {
    color: red;
    cursor: pointer;
    margin-right: 10px;
  }
}