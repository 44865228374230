.divider-top {
  margin-top: 2.2rem;
  margin-bottom: 1.8rem;
}

.divider-bottom {
  margin-top: 1.6rem;
  margin-bottom: 1.5rem;
}

.delete-photo-button {
  cursor: pointer;
  color: $red;
  font-size: 13px;
  margin-left: 13px;
}

.upload-photo-button {
  cursor: pointer;
  color: $light-blue;
  font-size: 13px;
  margin-left: 13px;

  &:focus {
    outline: none;
  }
}

.profile-card-title {
  color: $gray;
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  margin: .5rem 0;
}

.image-wrapper {
  display: inline-block;

  .profile-logo {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    object-fit: cover;
  }
}

.profile-dropdown-menu {
  .dropdown-menu {
    left: -340% !important;
  }
}

.notification-text {
  font-size: 16.3px;
  font-weight: 500;
  color: $gray;

  &::after,
  &::before {
    cursor: pointer;
  }
}

.input-wrapper {
  position: relative;

  .notification-email {
    position: absolute;
    font-size: 1.2rem;
    color: $red;
    top: 36px;
    right: 8px;
  }
}

button[disabled] {
  background-color: $light-blue;
  border-color: $light-blue;
}

.upload-text {
  color: $light-gray;
  font-size: 14px;
  font-weight: 500;
}

.btn-submit {
  padding: 7px 38px !important;
}

.image-profile {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  object-fit: cover;
}

.change-photo:focus {
  outline: none;
}