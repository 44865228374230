.orange-background {
  background-color: rgba(255, 116, 68, 0.25);
} 

.purple-background {
  background-color: rgba(85, 110, 230, 0.25);
}

.blue-background {
  background-color: rgba(38, 132, 255, 0.25);
}

.green-background {
  background-color: rgba(52, 195, 143, 0.25);
}

.icon-sm {
  height: 2.5rem;
  width: 2.5rem;
}

.media.d-cursor-none {
  cursor: auto;
}

.dashboard-item {
  cursor: pointer;

  &:hover {
    color: $blue;
  }
}

.nav-pills {
  .nav-item {
    .document-nav {
      border-radius: 3px;
      color: #495057;     
      font-size: 13px;
      font-weight: 500;
      padding: .5rem 2rem;

      &.nav-active {
        background-color: $light-blue;
        color: $white;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .nav-pills {
    .nav-item {
      .document-nav {
        padding: .5rem 1.2rem;
      }
    }
  }
}

.dashboard-line {
  margin-top: 0;
}

.card .dashboard-documents-card {
  position: relative;
  min-height: 283px;
  height: auto;
}

ul {
  list-style: none;
  padding-left: 0;
}

.actions-list {
  margin-bottom: 0;

  li {
    color: $gray;
    font-size: 13px;
    letter-spacing: 0;
    cursor: pointer;

    .action {
      display: flex;
      color: $gray;

      p {
        margin-bottom: 0;
        transition: color .3s ease;
      }

      &__date {
        width: 45%;
      }

      &__text {
        width: 55%;
      }
    }

    &:hover {
      .action p {
        color: $light-blue;
      }
    }
  }  
}

.dashboard-container {
  min-height: 100%;
}

.card .dashboard-action-card {
  position: relative;
  min-height: 267px;
  height: auto;
}

.training-list {
  li.event-list {
    padding: 0px 0px 32px 30px;
    transition: .3s ease;
    cursor: pointer;

    h5 {
      margin-bottom: 0;
      transition: .3s ease;
    } 

    &:hover {
      color: $light-blue;

      h5 {
        color: $light-blue;
      }
    }
  }
}

%btn-template {
  color: #FFFFFF;
  background-color: $light-blue;
  border: 1px solid $light-blue;
}

.btn.button-action {
  @extend %btn-template;
  margin: 5px 0px 5px 16px;
  padding: 8px 17px 8px 13px;
  border-radius: 6px;
  font-weight: 500;
  font-size: 13px;

  &:not(:disabled):not(.disabled) {
    &:hover, &:active, &:focus {
      color: $white;
      background-color: $blue-100;
      border: 1px solid $blue-100;
    }
  }

  img {
    margin-right: 4px;
  }
}

@media screen and (max-width: 768px) {
  .btn.button-action {
    width: 100%;
    margin: 16px 0;
    // padding: 8px 16px;
  }
}

.btn.due-title-button {
  font-size: 11px;
  letter-spacing: 0;
  line-height: 17px;
  padding: 6px 13px;
}

.middle {
  vertical-align: middle;
}

.no-data-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}