.link-trash {
  font-size: 1.2rem;
  vertical-align: middle;
}

.link-swicher {
  position: relative;
  display: flex;
  align-items: center;

  p {
    margin-left: .5rem;
    color: $gray;
    font-size: 13px;
  }
}

i.top-link {
  vertical-align: top;
  font-size: 1.4rem;
  margin-left: 0;
  margin-right: .2rem;
}

%btn-delete-template {
  background-color: $red;
  border: 1px solid $red;
  color: $white;
}

.btn.button-delete {
  @extend %btn-delete-template;
  border-radius: 6px;
  margin-right: 1rem;

  &:not(:disabled):not(.disabled) {

    &:hover,
    &:active,
    &:focus {
      background-color: $red-hover;
      border: 1px solid $red-hover;
    }
  }
}

.control-label {
  display: flex;
  align-items: center;

  .info-icon-user {
    margin-left: .2rem;
  }
}

.user-email-right {
  text-align: right;

  .user-email-block {
    justify-content: flex-end;
    word-break: break-word;

    .label-block {
      margin-right: 0rem !important;
      margin-left: 1rem;      
      
    }
  }
}

@media screen and (max-width: 767px) {
  .user-email-right {
    text-align: left;

    .user-email-block {
      justify-content: flex-start;
      word-break: break-word;

      .label-block {
        margin-left: 0rem;                
      }
    }
  }
}