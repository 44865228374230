.login-header {
  padding: 2.68rem 0;

  h5, p {
      margin-bottom: 0;
      color: #FFF;   
  }

  h5 {
      line-height: 1.63rem;
  }
}

.text-password {
  color: #495057;
  text-decoration: underline !important;
}

.form-button{
  font-size: 1rem !important;
}

button[type="submit"]{
  background-color: #2684ff;
  color: #FFF;

  &:hover {
      color: #FFF;
      background-color: #1f6ed6;
  }
}

.confirm-password-header {
  padding: 1.375rem 0;
  background-color: #054c7c;

  p, h5 {
    line-height: 1.63;
  }

  h5 {
    color: #f8f8fb;
    margin-bottom: 4px;
    font-weight: 600;
  }

  .logo-container {
    min-height: 34px;
  }

  p {
    color: #ffffff;
    max-width: 280px;
    margin: 0px auto;
  }
}

.form-group.form-confirm {
  margin-bottom: 2rem;
}

.text-right .btn-confirm {
  font-size: 1rem;
}

.login-header {
  padding: 2.1em 0;

  h5, p {
      margin-bottom: 0;
      color: #FFF;   
  }

  h5 {
      line-height: 1.63rem;
  }
}

.text-password {
  color: #495057;
  text-decoration: underline !important;
}

.alert-message {
  margin-top: 13px;
}

button[type="submit"]{
  color: #FFF;
  background: #2684ff;

  &:hover {
      color: #FFF;
  }
}

.main-logo {
  margin-left: 1rem;
}

.login-header {
  padding: 2.1em 0;
  background-color: #054c7c;

  .logo-container {
      min-height: 34px;
  }

  p {
      color: #ffffff;
      margin-bottom: 0;
  }
}

.text-password {
  color: #495057;
  text-decoration: underline !important;
}

button[disabled] {
  cursor: auto;
}

button[type="submit"] {
  color: #ffffff;
  background: #2684ff;

  &:hover {
      color: #FFF;
  }
}

.custom-control-input:checked~.custom-control-label::before {
  background-color: #2684ff !important;
  border: #2684ff !important;
}