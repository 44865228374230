.Toastify__toast-container {
  min-width: 320px;
  max-width: 600px;
  width: auto;
  text-align: center;
 
  

  .Toastify__toast-body {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;

  }
}

.Toastify__toast--success {
  min-height: 51px;
  background: #61cea6;
  border-radius: 4px;
  border: solid 1px #34c38f;
}

.Toastify__toast--error {
  min-height: 51px;
  border-radius: 4px;
}