.accordion-custom {
  border-bottom: 1px solid $gray-800;
  padding: 24px 0;
  cursor: pointer;

  .accordion-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $light-gray;
    font-size: 16px;
    font-weight: 500;

    &_left {
      display: flex;
      align-items: center;

      .icon-help {
        transition: .4s ease color;

        &:hover {
          color: green;
        }
      }
    }

    .arrow {
      font-size: 1.5rem;
      transition: .3s ease transform;
    }

    .downArrow {
      transform: rotate(180deg);
    }

    .green-arrow {
      color: green;
    }
  }
}

.accordion-collapse-content {
  color: $gray;
  font-size: 14px;
  padding: 1.7rem 0 0 1.7rem;

  p {
    margin-bottom: 1rem;

    b {
      text-transform: uppercase;
      font-weight: 600;
    }
  }

  .sub-title {
    color: $light-gray;
    font-size: 16px;
    font-weight: 500;
  }
}

button.button_blue {
  background-color: $light-blue;
  border: 1px solid $light-blue;
  line-height: 20px;
  padding: 8px 21px;

  &:not(:disabled):not(.disabled) {

    &:hover,
    &:active,
    &:focus {
      background-color: $blue-100;
      border: 1px solid $blue-100;
    }
  }
}

.info-column__text {
  a {
    text-transform: none;
  }
}

.nav-back {
  text-transform: none;
}

.card-custom-title {
  color: $gray;
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  margin: .5rem 0;
}

.cursor {

  &:after,
  &:before {
    cursor: pointer
  }
}

.custom-select-wrapper {
  width: 150px;
  margin: .2rem 0 1rem 0;

  .custom-select-revision {
    height: calc(1.7em + 2px);
    padding: 0.1rem 1.75rem 0.1rem 0.75rem;
    color: $light-gray-100;

    option {
      color: $gray;
    }
  }
}

.custom-switch-radio {
  min-width: 25px;
  min-height: 25px;
  margin-right: 5px;
}

.drop-zone-file {
  display: block;
  margin-top: 2rem;
  border: 2px dashed $white-100;
  padding: 10px 2px;
  cursor: pointer;

  div {
    outline: none;
  }

  .upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      font-size: 1rem;
    }

    .cloud {
      font-size: 2.3rem;
    }
  }

  .choose-file {
    display: inline-block;
    background-color: $white-200;
    font-size: 13px;
    color: #495057;
    padding: 3px 7px 3px 8px;
    border-radius: 4px;
    border: solid 1px $white-200;
    outline: none;

    cursor: pointer;
  }

  .file-text {
    text-align: center;
    padding: 0 10px;
  }


}

.drag-active {
  border: 2px dashed $white-100;
  border-radius: 6px;
}

.icon-button {
  cursor: pointer;

  .icon {
    margin: 0 .3rem 0 .7rem;
  }
}

.text-pagination {
  color: #74788d;
  font-size: 0.8125rem;
}

.tags-list {
  &__title {
    font-size: 0.8125rem;
    font-weight: bold;
    color: $gray;
    margin-bottom: .5rem;
    margin-top: 1rem;
  }

  .tag-menu-container {
    display: flex;
    flex-wrap: wrap;

    .filter-item {
      background-color: $white-300;
      color: $gray;
      padding: 0 8px;
      margin-right: .5rem;
      margin-bottom: .5rem;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}

.text-checkbox {
  .custom-control {
    display: inline;
  }
}

.user-email-block {
  &__title {
    color: $gray;
    font-size: 13px;
    font-weight: bold;
  }
}

.d-cursor-none {
  cursor: auto;
}

.option-container {
  .custom-control {
    .grey {
      opacity: .5;
    }
  }
}

.custom-tooltip {
  max-width: 500px;
}

.icon-tooltip {
  font-size: .725rem !important;
  border-radius: 6px !important;
  box-shadow: 0 0 36px rgba(0, 0, 0, 0.1);
  color: $light-blue !important;
  border-color: $white !important;
  background-color: $white !important;
}

.icon-tooltip-white {
  font-size: .725rem !important;
  border-radius: 6px !important;
  box-shadow: 0 0 36px rgba(0, 0, 0, 0.1);
  color: $gray !important;
  border-color: $white !important;
  background-color: $white !important;
  opacity: 1 !important;
  max-width: 360px;
}

.text-tooltip {
  font-size: .725rem !important;
  border-radius: 6px !important;
  box-shadow: 0 0 36px rgba(0, 0, 0, 0.1);
  color: $light-blue !important;
  border-color: $white !important;
  background-color: $white !important;
  min-width: 250px !important;
  text-align: center;
}