.general {
  hr {
    margin: 2rem 0;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h6 {
      font-size: 16px;
      font-weight: 600;
      color: $gray;
      margin-bottom: 0;
    }

    .general__btn-edit {
      display: flex;
      align-items: center;
      font-weight: 300;
      background-color: $blue;
      color: $white;
      outline: none;
      border: none;
      padding: 6px 12px;
      border-radius: 8px;
      border: solid 1px $blue;
      font-size: 13px;
      transition: .3s ease;

      &:hover {
        border: solid 1px $blue-hover;
        background-color: $blue-hover;
      }

      i {
        margin-right: 6px;
        font-size: 18px;
      }

      &_blue {
        background-color: $light-blue;
        border: solid 1px $light-blue;
      }
    }
  }



  &-info {
    margin-top: 2rem;
  }

  &-revision {
    margin-top: 2rem;
  }

  &-attached {
    .document-attached {
      display: grid;
      grid-template-columns: 50px 1fr;
      align-items: center;
      margin-top: 20px;

      .file-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        cursor: pointer;
        background-color: rgba(85, 110, 230, 0.25);
        border-radius: 50%;

        i {
          color: $blue;
          font-size: 1.6rem;
        }
      }

      .file-info {
        margin-left: 24px;
        word-break: break-word;

        &__name {
          font-size: 14px;
          font-weight: 500;
          color: $light-gray-200;
          margin-bottom: 4px;
        }

        &__size {
          font-size: 12px;
          font-weight: 400;
          color: $gray;
          margin-bottom: 0;
        }

        &__remove {
          font-size: 1.2rem;
          justify-self: end;
        }
      }

      &.document-attached_delete {
        grid-template-columns: 50px 1fr 1fr;
      }

      .file-delete {
        justify-self: end;
        font-size: 1.2rem;
        cursor: pointer;
      }
    }

    .bx .bxs-trash-alt {
      font-size: 1.2rem;
    }
  }

  &-status,
  &-responsible {
    display: flex;
    justify-content: space-between;
  }
}

.info-column {
  &__title {
    font-size: 13px;
    font-weight: bold;
    color: $gray;
    margin-bottom: 8px;
  }

  &__text {
    font-size: 13px;
    font-weight: 400;
    color: $gray;
    margin-bottom: 0;
    line-height: 1.54;
    letter-spacing: normal;

    &-bottom {
      margin-bottom: 1rem;
    }
  }

  &__link {
    color: $blue;
    cursor: pointer;
  }

  &_right {
    text-align: right;
  }

  textarea {
    resize: none;
  }

  .revision-group {
    display: flex;
    flex-wrap: wrap;
  }
}

.v-middle {
  vertical-align: middle;
}

.d-cursor-pointer {
  cursor: pointer !important;
}

.quality-assign {
  .quality-reviewers {
    hr {
      margin: 1rem 0;
    }

    margin-top: 20px;

    .reviewer-list {
      list-style: none;
      padding-left: 0;

      .reviewer-item {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        font-size: 14px;
        font-weight: 500;

        p {
          margin-bottom: 0;
        }

        &__name {
          color: $gray
        }

        &__email,
        &__role {
          color: rgba(73, 80, 87, 0.7);
        }

        &__role {
          justify-self: end;
        }
      }
    }
  }
}

.general {
  .quality-edit-reviewer {
    hr {
      margin: 1rem 0;
    }

    .info-column {
      &__title {
        margin-bottom: 1.43rem;
      }
    }

    .quality-item {
      p {
        font-size: 13px;
        font-weight: 500;
        color: rgba(73, 80, 87, 0.7);

        &.quality-item_blue {
          color: $light-blue;
          cursor: pointer;
        }
      }

      .role-block {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        p {
          margin-right: 1.5rem;
        }

        i {
          cursor: pointer;
          font-size: 1.2rem;
        }
      }
    }
  }
}

.trash-middle {
  vertical-align: middle;
}

.trash-disabled {
  opacity: .2;
}

.ref-link-item {
  display: flex;
  justify-content: space-between;

  i {
    font-size: 1.2rem;
  }
}

.references-container {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    color: $gray;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 1.5rem;

    li {
      margin-top: 1rem;
      font-weight: 400;

      a {
        display: flex;
        align-items: center;
        font-weight: 500;

        i {
          margin-right: .2rem;
          font-size: 1.3rem;
        }
      }
    }
  }
}

.general-header {
  flex-wrap: wrap;

  &__title {
    display: flex;
    align-items: center;

    i {
      margin-left: 0.4rem;
      font-size: 1.2rem;

      &:hover {
        color: $light-blue;
      }
    }
  }

  .header-block {

    h6 {
      margin-bottom: 3px;
    }

    p {
      color: rgba(73, 80, 87, 0.7);
      font-size: .75rem;
      font-weight: 500;
      margin-bottom: 0;
    }
  }
}

.general {
  .review-responses {
    display: flex;
    justify-content: space-between;
  }

  textarea {
    resize: none;
  }
}

.document-text {
  font-size: .875rem;
  font-weight: 500;
}

.review-comment {
  margin-top: 1rem;

  &__title {
    font-size: 13px;
    font-weight: bold;
    color: $gray;

    i {
      vertical-align: middle;
      font-size: 1rem;
      transform-origin: center;
      margin-left: .5rem;
    }

    .downArrow {
      transform: rotate(180deg);
    }
  }
}

.general-file {
  display: inline-block;
  margin-top: 2rem;

  div {
    outline: none;
  }

  .choose-file {
    display: inline-block;
    background-color: $white-200;
    font-size: 13px;
    color: $gray;
    padding: 3px 7px 3px 8px;
    border-radius: 4px;
    border: solid 1px $white-200;
    outline: none;

    cursor: pointer;
  }

  .file-text {
    text-align: center;
    padding: 0 10px;
  }
}

.info-column {
  i {
    margin-left: .3rem;
    font-size: 1.2rem;
    vertical-align: middle;
    cursor: pointer;
  }
}


.info-column {
  .btn-create {
    background-color: $blue;
    color: $white;
    padding: 7px 24px 7px 14px;
    border: 1px solid $blue;
    border-radius: 8px;

    i {
      margin-left: 0;
      font-size: 1.3rem;
    }

    &:hover {
      color: $white;
    }
  }
}

.requires-text {
  font-size: 13px;
  font-weight: 500;
}

.radio-container {
  display: flex;

  .form-check {
    display: flex;
    align-items: center;

    &:nth-child(2) {
      margin-left: 1rem;
    }

    .form-check-input {
      width: 25px;
      height: 25px;
    }

    span {
      display: flex;
      margin-left: 1rem;
      color: $gray;
      font-size: 13px;
      font-weight: 500;

      .info {
        align-self: center;
        margin-left: .5rem;
        font-size: 1rem;
      }
    }
  }
}

.col {
  .btn-training {
    border: 1px solid $blue;
    color: $white;
    background-color: $blue;

    &:hover {
      color: $white;
    }

    i {
      margin-left: 5px;
      vertical-align: middle;
      font-size: 1.4rem;
    }
  }
}

.info-tooltip {
  vertical-align: middle;
  margin-left: .5rem;
  font-size: 1rem;

  &:hover {
    color: $light-blue;
  }
}

.delete-training-icon {
  font-size: 1.2rem;
}

.container-bottom {
  margin-bottom: 100px;
}

.nav-back {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  color: $light-blue;
  cursor: pointer;

  .arrow {
    color: inherit;
    font-size: 1.2rem;
  }
}

.nav-line {
  width: calc(100% + 2.1rem);
  transform: translateX(-1.05rem);
}

.nav-custom-tabs {
  margin: 0;
}

.nav-button {
  margin-top: 1rem;
  width: 100%;
}