.header-logo-lg {
  max-height: 50px;
  width: auto;
  max-width: 100%;
}

.disabled-link {
  pointer-events: none;
}

.media {
  cursor: pointer;
  .media-body {
    p {
      white-space: pre-wrap;
    }
  }
}

.bell-container {
  background-color: #E9E9E9;
  border-radius: 50%;
  margin-right: .75rem;
  
  i {
    padding: 8px;
    font-size: 1.1rem;
  }
}

.sidebar {
  max-height: 100%;
}

.navigation-bottom {
  position: absolute;
  bottom: 10px;
  width: 100%;
  padding: 0 1.5rem;

  &__block {
    margin-bottom: 1rem;
    span {
      color: #A6B0CF;
      cursor: pointer;
      transition: .3s ease color;

      i {            
        font-size: 1.2rem;
        vertical-align: bottom;
        margin-right: 1rem;
        color: #6a7187;
        transition: .3s ease color;
      }

    &:hover {
      color: $white;

      i {            
        color: $white;
      }
      }
    }
  }
}

.user-content {
  .inner-content{
    padding: 94px 12px 60px;

    .custom-card {
      max-width: 982px;
      min-height: 637px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;

      &__body {
        max-width: 470px;

        p {        
          font-size: 14px;
          text-align: center;
          color: rgba(83, 90, 98, 0.5);
          font-weight: 500;
        }
      }      
    }
  }
}

.success-icon {
  font-size: 24px;
}

#Tooltip-name {
  word-break: break-word;
}