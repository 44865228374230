.file-info__remove {
  i {
    font-size: 1.2rem;
  }
}

.list-container {
  &__title {
    margin-top: 2rem;
    font-size: 13px;
    font-weight: bold;
    color: $gray;
    margin-bottom: 8px;
  }

  .list {
    margin-top: 1rem;
    list-style: none;
    padding-left: 0;

    li {
      color: $light-blue;
      font-size: 13px;
      font-weight: 500;

      .cursor {
        cursor: pointer;
      }

      hr {
        margin: 0.5rem 0;
      }
    }
  }
}