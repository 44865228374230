.permissions-document {
  h5 {
    font-weight: 600;
    letter-spacing: 0;
    line-height: 25px;
    color: $gray;
    margin-bottom: 1rem;
  }

  p {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 22px;
    color: rgba(83, 90, 98, 0.8);
  }
}

.accordion {
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 0;
    border-top: 1px solid $gray-800;
    border-bottom: 1px solid $gray-800;

    &__radio {
      display: flex;
      align-items: center;
      padding-left: 2.5rem !important;

      .form-check-input {
        width: 25px;
        height: 25px;
        margin-top: 0;
        margin-left: -2.25rem;
      }

      p {
        // display: flex;
        // align-items: center;
        margin-bottom: 0;
        margin-right: 50px;
        color: $light-gray;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 22px;

        i {
          vertical-align: middle;
        }
      }

      .info {
        margin-left: .2rem;
        font-size: 1.2rem;

        &:hover {
          color: $blue;
        }
      }
    }

    .arrow {
      font-size: 1.5rem;
    }

    .downArrow {
      transform: rotate(180deg);
    }
  }
}

.collapse-content {
  padding: 15px 55px;

  .section-text {
    padding-top: .5rem;
    margin-bottom: .5rem;
    color: $light-gray-100;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
  }

  .option-container {
    padding: 8px 0;

    label {
      display: flex;
      align-items: center;
      color: rgba(83, 90, 98, 0.5);
      font-size: 14px;
      letter-spacing: 0;
      line-height: 22px;
    }

    .info {
      margin-left: .2rem;
      font-size: 1.2rem;

      &:hover {
        color: $blue;
      }
    }

    .custom-control-input[disabled]~.custom-control-label,
    .custom-control-input:disabled~.custom-control-label {
      color: rgba(83, 90, 98, 0.5);
    }

    &.active {
      label {
        color: rgba(83, 90, 98, 0.8);
      }
    }
  }

}

.custom-control-label::after {
  cursor: pointer;
}


#tooltip-accordion {
  top: 10px;
  min-width: 308px;
  background-color: $white;
  color: $gray;
  font-size: 12px;
  padding: .7rem;
  border-radius: 6px;
  box-shadow: 0 11px 36px 0 rgba(0, 0, 0, 0.1);
  letter-spacing: 0;
  line-height: 18px;
  text-align: left;

  span {
    color: $gray;
  }
}

#tooltip-accordion+.arrow {
  display: none;
}
