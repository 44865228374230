// 
// _table.scss
// 

.table {
  th {
    font-weight: $font-weight-bold;
  }
}

//Table centered
.table-centered {

  td,
  th {
    vertical-align: middle !important;
  }
}

.table-nowrap {

  th,
  td {
    white-space: nowrap;
  }
}

.column-sort {
  cursor: pointer;
}

.table-column {
  i {
    font-size: 1.2rem;
  }
}

tbody tr td a {
  color: #000;
}

.global-column {
  max-width: none;

  @media screen and (max-width: 768px) {
    min-width: auto !important;
    width: auto !important;
    max-width: 100% !important;
  }
}

.table-custom {

  th,
  td {
    border: 1px solid $gray-800;
  }
}

.resizing {
  position: absolute;
  height: 45px;
  top: 0;
  right: 0;
  width: 5px;
  cursor: col-resize;
  user-select: none;

  &:hover {
    border-right: 3px solid lightgrey;
  }
}

.table-custom.table {
  width: auto;
}

.d-cursor-scroll {
  cursor: col-resize !important;
}

.category-logo {
  fill: #495057;
  margin-right: .25rem;
}

.filter-container {
  &:hover {
    .category-logo {
      fill: #2684FF;
    }
  }
}

.active-logo {
  fill: #2684FF;
}

.d-cursor {
  cursor: pointer;
}

.react-datepicker__input-container {
  vertical-align: middle;
}

.dropdown-logs-info {
  width: 230px;

  &__menu {
    max-height: 235px;
    overflow-x: hidden;
    overflow-y: scroll;

    .dropdown-text {
      display: flex;
      color: #495057;
      font-size: 13px;
      font-weight: 500;

      &.d-cursor-none {
        cursor: auto;
      }
    }
  }

  &__footer {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    font-size: 13px;

    button {
      border: none;
      outline: none;
      font-weight: 500;
    }

    &-clear {
      color: #2684FF;
      text-decoration: underline;
      background-color: $white;
    }

    &-save {
      border-radius: 6px;
      background-color: #2684FF;
      color: #FFFFFF;
      padding: .3rem 1.5rem;
    }
  }
}

.date-container {
  z-index: 2;

  .react-datepicker__tab-loop {
    .react-datepicker-popper {
      .react-datepicker {
        border: none;
        border-radius: 8px;
        box-shadow: 0 11px 36px 0 rgba(0,0,0,0.1);

        .react-datepicker__triangle {
          display: none;
        }

        .react-datepicker__month-container {
          float: none;
          
          .react-datepicker__header  {
            background-color: #FFFFFF;
            border-bottom: none;

            .react-datepicker__current-month {
              color: #495057;
              font-family: 'Poppins';
              font-size: 0.8125rem;
              font-weight: 600;
            }
          }

          .react-datepicker__month {
            margin: 0 .4rem .4rem .4rem;
          }
          
          .react-datepicker__week {

            .react-datepicker__day {
              &:focus {
                outline: none;
              }
            }

            .react-datepicker__day--keyboard-selected {
              background-color: #2684FF;
              border-radius: 0;

              &:focus {
                outline: none;
              }
            }

            .react-datepicker__day--in-selecting-range {
              background-color: #2684FF;
              border-radius: 0;
            }

            .react-datepicker__day--in-range {
              background-color: #2684FF;
              border-radius: 0;
            }
          }
        }

        .react-datepicker__today-button {
          background-color: #fff;
          border-top: none;
          display: flex;
          justify-content: flex-end;

          .today {            
            margin: 0rem 1rem .75rem;
            color: #2684FF;
            font-family: 'Poppins';
            font-size: 0.8125rem;
            font-weight: 500;
            cursor: pointer;
          }          
        }
      }
    }
  }

  &-header {
    margin: 5px 16px 5px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__date {
      color: #495057;
      font-family: 'Poppins';
      font-size: 0.8125rem;
      font-weight: 600;
    }

    &__arrows {
      display: flex;
      align-items: center;
      color: rgba(73,80,87,0.5);
      font-size: 1.5rem;
    }
  }
}

.export-logs-button {
  background-color: #556EE6;
  border-radius: 6px;
  font-size: 11.37px;
  text-align: center;
  color: #fff;
  padding: 5.5px 15px;
  outline: none;
  border: none;

  &:disabled {
    background-color: rgba(85, 110, 230, 0.5) !important;
  }
}

.document-dropdown {
  &__header {
    margin: .5rem 1rem;
    color: #495057;
    font-size: 13px;
    font-weight: 600;
  }

  .cursor {
    cursor: pointer;
  }

  &__body {
    margin: 0 1rem;

    .dropdown-text {
      display: flex;
      color: #495057;
      font-weight: 500;
      font-size: 13px;
      cursor: auto;
    }
  }

  &__footer {
    display: flex;

    button {
      margin: 0 1rem;
    }

    .select-button {
      border: none;
      color: #2684FF;
      outline: none;
      background: transparent;
      white-space: nowrap;

      span {
        text-decoration: underline;
      }
    }
  }

  hr {
    background: #EFF2F7;
  }
}

.dropdown-menu:focus {
  outline: none;
}

.active-filter {
  color: #2684FF;
}

.filter-menu-container {
  display: flex;
  flex-wrap: wrap;

  .filter-item {
    background-color: #D8D8D8;
    color: #495057;
    padding: 3px 8px;
    margin-right: .5rem;
    margin-bottom: .5rem;

    i {
      margin-left: 5px;
      vertical-align: middle;
    }

    &.cursor {
      cursor: pointer;
    }
  }
}

.file-container {
  margin-right: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  .setting {
    font-size: 22px;
  }

  transition: .3s ease color;

  &:hover {
    color: #2684FF;
  }
}

.filter-container {
  transition: .3s ease color;

  &:hover {
    color: #2684FF;
  }
}

.icon_middle {
  vertical-align: middle;
  margin-left: .1rem;
}

.activated-column {
  display: flex;
  justify-content: center;
}

.dropdown-file-item.dropdown-item {
  font-size: 13px;
  font-weight: 500;
  transition: .2s ease color;

  &:hover {
    color: #2684FF;
  }
}

.active-dropdown {
  color: #2684FF;
}

.d-cursor {
  cursor: pointer;
}

th.column-hidden,
td.column-hidden {
  display: none;
}

.th {
  // z-index: 1;
}

.d-pointer {
  cursor: pointer !important;
}

.thead-light tr th.head-sort {
  span {
    position: relative;
  
    &:after {
      content: "\2193";
      right: -1.35em;
      bottom: 0.13rem;
    }
  
    &::before {
      content: "\2191";
      right: -1em;
      bottom: .07rem;
    }
  
    &::after,
    &::before {
      position: absolute;    
      display: block;
      opacity: .4;
    }
  }

  &.sorting-asc {
    span::before {
      opacity: 1;
    }
  }

  &.sorting-desc {
    span::after {
      opacity: 1;
    }
  }
}


.table .thead-light th {
  padding: .75rem 1.25rem .75rem .75rem;

  &:first-child {
    padding: .75rem;
  }
}

.table-document {

  thead,
  tbody {
    tr {

      th,
      td {
        max-width: 80px;
      }
    }
  }

  th,
  td {
    .document-checkbox {
      margin-bottom: 0;
      padding-left: 0;

      .custom-control-label {

        &:after,
        &:before {
          cursor: pointer;
        }
      }
    }
  }
}

.table-checkbox {
  padding-left: 1.85rem !important;
}

@media screen and (max-width: 767px) {
  .table-document {

    thead,
    tbody {
      tr {

        th,
        td {
          max-width: 100%;
        }
      }
    }
  }

  .table-checkbox {
    padding-left: .75rem !important;
  }
}

th,
td {
  &.fixed-column {
    // z-index: 1;
    width: 48px;
  }
}

.actions-inner {
  min-width: 80px;

  i {
    transition: color .2s ease;

    &:hover {
      color: #2684FF;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }
}

.row {
  .selected-delete {
    border-radius: 4px;
    background-color: rgba(73, 80, 87, 0.05);
    padding: 14px 24px;

    &__action {
      color: #f46a6a;
      font-size: 11.4px;
      cursor: pointer;

      i {
        padding-right: 5px;
        font-size: 1rem;
        vertical-align: middle;
      }
    }
  }
}

.sort {
  cursor: pointer !important;
}

#btn-exist {
  background-color: #34c38f;
  border: #34c38f;
}

.search-input {
  max-width: 227px;
}

td {
  p {
    margin-top: 6px;
    margin-bottom: 6px;
  }
}

.setting {
  font-size: 20px;
  margin-right: 8px;
}

.show .tooltip.show {
  opacity: 1;
  top: 0px !important;
  z-index: 9999;
}


#tooltip-columns {
  background-color: #fff;
  color: #2684ff;
  font-size: 11.4px;
  padding: .7rem;
  width: 125px;
  min-height: 32px;
  border-radius: 6px;
  box-shadow: 0 11px 36px 0 rgba(0, 0, 0, 0.1);

  span {
    color: #2684ff;

    &.inactive {
      opacity: .5;
    }
  }
}

#tooltip-document {
  background-color: #fff;
  color: #2684ff;
  font-size: 11.4px;
  line-height: 17px;
  text-align: left;
  padding: .7rem;
  max-width: 400px;
  min-height: 32px;
  border-radius: 6px;
  box-shadow: 0 11px 36px 0 rgba(0, 0, 0, 0.1);

  span {
    color: #2684ff;

    &.inactive {
      opacity: .5;
    }
  }
}

#tooltip-columns+.arrow,
#tooltip-document+.arrow {
  display: none;
}

#tooltip-profile {
  background-color: #fff;
  color: #F46A6A;
  font-size: 11.4px;
  line-height: 17px;
  text-align: left;
  padding: .7rem;
  max-width: 270px;
  min-height: 32px;
  border-radius: 6px;
  box-shadow: 0 11px 36px 0 rgba(0, 0, 0, 0.1);

  .tooltip-title {
    font-weight: 600;
  }
}

#tooltip-columns+.arrow,
#tooltip-profile+.arrow {
  display: none;
}

.table-wrapper {
  display: block;
  width: 100%;
  min-height: 0px;
  max-height: 630px;
  overflow-y: auto;

  th {
    position: sticky;
    top: -1px;
  }
}

table {

  thead,
  tbody {
    tr {

      th,
      td {
        // max-width: 110px;
        // width: 300px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &.activate-column {
          // min-width: 140px;
          // max-width: 350px;
        }

        .td-before {
          display: none;
        }
      }
    }
  }
}

.btn,
.btn-primary {
  &:focus {
    box-shadow: none !important;
  }
}

@media screen and (max-width: 1224px) {
  .table-wrapper {
    height: auto;
    max-height: none;

    th {
      position: relative;
      top: 0;
    }
  }
}

@media screen and (max-width: 767px) {

  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  table {
    thead {
      display: none;
    }

    tbody {
      tr {
        border: 1px solid #000000;

        td {
          position: relative;
          display: flex;
          justify-content: space-between;
          width: 100%;
          max-width: 100%;

          span {
            position: relative;
          }

          p,
          div {
            text-align: left;
            display: block;
            left: 1rem;
            width: calc(70% - 20px);
            font-weight: 600;
          }

          .td-before {
            display: block;
          }

          .text-center {
            text-align: left !important;
          }

          &.activate-column {
            min-width: 100%;
            max-width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .search-input {
    max-width: 100% !important;
  }

  .search-box {
    width: 100%;
  }

  td p:nth-last-child(1) {
    padding-left: .4rem;
  }
}

.d-table-link {
  display: block;

  &:hover {
    color: #000;
  }
}