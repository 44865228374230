%icon-template {
  position: absolute;
  top: 50%;
  font-size: 1.1rem;
  transform: translateY(-50%);
}

.search-header {
  position: relative;
  align-self: center;


  .search-input {
    border-radius: 30px;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .search-icon {
    cursor: auto;
    @extend %icon-template;
    left: .7rem;
  }

  .clean-icon {
    @extend %icon-template;
    right: .7rem;
  }

  .spinner-container {
    position: absolute;
    top: 50%;
    right: 2rem;
    transform: translateY(-50%);

    .search-spinner {
      width: 1.2rem;
      height: 1.2rem;
    }
  }
}

.actions {
  display: flex;
  font-size: 1.2rem;
  align-items: center;

  i {
    transition: .2s ease color;

    &:hover {
      color: $light-blue;
    }
  }

  .active-icon {
    transition: .2s ease color;

    &:hover {
      opacity: 1;
      color: $light-blue;
    }
  }


  .muted-icon {
    opacity: .3;
  }
}

.organization-name {
  p {
    margin: 5px 0px;
  }
}

.column-title {
  cursor: pointer;
}

#btn-added {
  color: $white;
  background-color: $light-blue;
  border: $light-blue;
  transition: .3s ease;

  &:hover {
    background-color: $blue-100;
    color: $blue-100;
  }
}

.btn-muted {
  background-color: $gray-800 !important;
}

.btn-add-new {
  padding: 8px 32px !important;
}

.wrapper-select {
  padding-left: 1rem;
  width: 138px;
}

.search-input {
  max-width: 227px;
}

.pagination {
  display: flex;
  align-items: center;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
  margin-bottom: 0;
}

.text-pagination {
  color: $light-gray-100;
  font-size: 0.8125rem;
}

.pagination a {
  background-color: red;

  position: relative;
  display: block;
  background-color: $white;
  color: $light-gray-100;
  border-radius: 30px !important;
  margin: 0 3px;
  border: none;
  width: 32px;
  height: 32px;
  padding: 0;
  text-align: center;
  line-height: 32px;

  &:hover {
    z-index: 2;
    color: $blue-100;
    text-decoration: none;
    background-color: $gray-800;
    border-color: $gray-800;
  }

  &:focus {
    box-shadow: 0 0 0 0.15rem rgba(85, 110, 230, 0.25);
  }
}

.pagination__link {
  font-weight: bold;
}

.pagination__link--active a {
  color: $white;
  background: $blue-200;
}

.pagination .active a {
  z-index: 3;
  color: $white;
  background-color: $light-blue;
  border-color: $light-blue;
}

#tooltip-top {
  background-color: $white;
  color: $light-blue;
  font-size: 11.4px;
  padding: .7rem;
  width: 160px;
  min-height: 32px;
  border-radius: 6px;
  box-shadow: 0 11px 36px 0 rgba(0, 0, 0, 0.1);

  span {
    color: $light-blue;

    &.inactive {
      opacity: .5;
    }
  }
}

#tooltip-name {
  background-color: $white;
  color: $light-gray-100;
  font-size: 14px !important;
  width: 400px;
  max-width: 400px;
  min-height: 32px;
  border-radius: 6px;
  box-shadow: 0 11px 36px 0 rgba(0, 0, 0, 0.1);
}

#tooltip-name+.arrow {
  display: none;
}

#tooltip-top+.arrow {
  display: none;
}

.activated-column__organization {
  display: flex;
  justify-content: center;
}

i {
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

tr th {
  z-index: 1;
}

#tooltip-checkbox {
  background-color: $white;
  color: $light-blue;
  font-size: 11.4px;
  padding: .7rem;
  width: 360px;
  min-height: 32px;
  border-radius: 6px;
  box-shadow: 0 11px 36px 0 rgba(0, 0, 0, 0.1);

  span {
    color: $light-blue;

    &.inactive {
      opacity: .5;
    }
  }

  &.tooltip-inner {
    min-width: 350px;
  }
}

#tooltip-checkbox+.arrow {
  display: none;
}

.disabled-scroll {
  overflow: hidden !important;
}

.client-organization-card {
  height: 127px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  background-color: $white;
  box-shadow: 0 10px 20px 0 rgba(18, 38, 63, 0.03);
  border-radius: 4px;
  transition: .3s ease-in-out background-color;
  cursor: pointer;

  &__name {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    transition: .3s ease-in-out color;
  }

  &:hover {
    background-color: $light-blue;

    .client-organization-card__name {
      color: $white;
    }
  }
}

.dropzone {
  min-height: 60px;
  width: 60px;
  background: $gray-800;
  border-radius: 6px;

  .dz-message {
    font-size: 24px;
    width: 100%;
    margin: 0;
  }
}

.placeholder-image {
  width: 60px;
  height: 60px;
  background-color: $gray-800;
}

.current-image {
  img {
    max-height: 60px;
  }
}

.btn.btn-upload {
  background-color: $light-blue;
  color: $white;
  padding: 7px 38px;

  &:hover {
    color: $white;
    background-color: $blue-100;
  }
}

.user-group__activated-column {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .user-group__activated-column {
    justify-content: flex-start;
  }
}

.nav-tabs-custom .nav-item .nav-link {
  font-size: 1rem;
  font-weight: 600;
}

#btn-exist {
  color: $white;
  background-color: $green;
  border: $green;

  &:hover {
    background-color: darken($green, 5%);
    border: darken($green, 5%);
  }
}

.search-input {
  max-width: 227px;
}

tr {
  transition: .2s ease-in-out background-color;

  &:hover {
    background-color: #f7faff;
  }
}

td {
  p {
    margin-top: 6px;
    margin-bottom: 6px;
  }
}

.btn-active {
  width: 84px;
}

.actions {
  display: flex;
  font-size: 1.2rem;
  align-items: center;

  i {
    padding-right: 8px;

    &:last-of-type {
      padding-right: 0;
    }
  }

  .active-icon {
    transition: .2s ease color;

    &:hover {
      opacity: 1;
      color: $light-blue;
    }
  }


  .muted-icon {
    opacity: .3;
  }
}

.select-wrapper {
  width: 65px;
}

.column-user-active {
  min-width: 90px;
}

.column-admins-active {
  min-width: 170px;
}

.column-users-active {
  min-width: 192px;
}

@media screen and (max-width: 575px) {
  .select-wrapper {
    width: 100%;
  }
}

#tooltip-access {
  top: 10px;
  min-width: 480px;
  background-color: $white;
  color: $gray;
  font-size: 12px;
  padding: .7rem;
  border-radius: 6px;
  box-shadow: 0 11px 36px 0 rgba(0, 0, 0, 0.1);
  letter-spacing: 0;
  line-height: 18px;
  text-align: left;

  span {
    color: $gray;
  }
}

.general {
  .access {
    i {
      margin-left: .3rem;
      font-size: 1.2rem;
      vertical-align: bottom;

      &:hover {
        color: $light-blue;
      }
    }

  }
}

#tooltip-access+.arrow {
  display: none;
}

.user-group {
  &__title {
    margin-top: 2rem;
    font-size: 13px;
    font-weight: bold;
    color: $gray;
    margin-bottom: 8px;
  }

  &-list {
    margin-top: 1rem;
    list-style: none;
    padding-left: 0;

    li {
      color: $light-blue;
      font-size: 13px;
      font-weight: 500;

      .cursor {
        cursor: pointer;
      }

      hr {
        margin: 0.5rem 0;
      }

      p {
        color: $gray;
      }
    }
  }
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.5rem;
}