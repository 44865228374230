.text-exist {
  font-weight: 500;
  line-height: 2;
  font-size: 14px;
}

.btn-hidden {
  display: none !important;
}

.close-icon-modal {
  font-size: 1.7rem;
  color: $black;
}

button.delete-btn {
  background-color: $red !important;
  border: $red;

  &:hover {
    background-color: $red-hover;
    border: $red-hover;
  }
}

.text-modal {
  font-weight: 500;
  line-height: 28px;
  font-size: 14px;
}

.btn-hidden {
  display: none !important;
}

.text-active {
  font-weight: 500;
  line-height: 2;
  font-size: 14px;
}

.input-error-label {
  color: $red;
}

.form-group {
  .input-error {
    border: 1px solid $red;
  }
}


.input-error-name {
  color: $red;
  font-size: 80%;
  margin: .2rem 0;
}

.custom-control-input:checked~.custom-control-label::before {
  background-color: $light-blue !important;
  border: $light-blue !important;
}

.custom-checkbox {
  margin-bottom: 0px;
}

.modal .modal-columns {
  max-width: 340px;
}

.btn-edit {
  color: $white !important;
  background-color: $light-blue !important;

  &:hover {
    color: $white !important;
    background-color: $blue-100 !important;
  }
}

.modal-text {
  font-size: 13px;
  font-weight: 500;
  color: $light-blue;
  cursor: pointer;
  text-decoration: underline;
}

.hidden {
  display: none !important;
}

.text-active {
  font-weight: 500;
  line-height: 2;
  font-size: 14px;
}

.btn-hidden {
  display: none !important;
}

.tooltip-training {
  cursor: pointer;
  margin-left: 5px;
  vertical-align: center;
}

button.delete-btn {
  background-color: $red !important;
  border: $red;

  &:hover {
    background-color: $red-hover;
    border: $red-hover;
  }
}

.text-modal {
  font-weight: 500;
  line-height: 28px;
  font-size: 14px;
}

.text-modal {
  font-weight: 500;
}

.delete-user-title {
  i {
    margin-left: 0.3rem;
    vertical-align: middle;

    &:hover {
      color: rgba(46, 46, 255, 0.829);
    }
  }
}

.text-active {
  font-weight: 500;
  line-height: 2;
  font-size: 14px;
}

button.btn-deactive {
  background-color: $red !important;
}

button.delete-btn {
  background-color: $red !important;

  &:hover {
    background-color: $red-hover !important;
    border: $red-hover;
  }
}

.text-modal {
  font-weight: 500;
  line-height: 28px;
  font-size: 14px;
}

button.btn-edit {
  background-color: $light-blue;
}

.btn-hidden {
  display: none !important;
}