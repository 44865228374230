.document {
  .document-header {
    display: flex;

    .btn-end {
      align-self: flex-start;
      margin-left: auto;
      margin-right: 0px;
      white-space: nowrap;
    }

    .document-title {
      color: $gray;
      font-size: 16px;
      font-weight: 600;
      line-height: 25px;
    }

    .document-text {
      color: rgba(83, 90, 98, 0.8);
      font-size: 14px;
    }
  }



  .document-empty {
    display: flex;
    flex-direction: column;
    margin: 135px 0 150px;
    align-items: center;

    &__title {
      color: $gray;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .btn-create {
    background-color: $light-blue;
    color: $white;
    padding: 8px 34px;
    border-radius: 6px;

    &:hover {
      color: $white;
      background-color: $blue-100;
    }

    &_lg {
      padding: 8px 40px;
    }
  }

  .document-list {
    margin-top: 45px;


    &__item {
      .document-inner {
        margin: 0 17px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          color: $gray;
          font-size: 13px;
          letter-spacing: 0;
          line-height: 20px;
          margin-bottom: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        i {
          font-size: 1.2rem;
        }

      }
    }
  }
}

.setting-icon {
  font-size: 1.2rem;
  margin-left: .2rem;
  vertical-align: middle;

  &:hover {
    color: $light-blue;
  }
}

%btn-template {
  color: $white;
  background-color: $light-blue;
  border: 1px solid $light-blue;
}

.btn.button-tags {
  @extend %btn-template;
  padding: 8px 34px;

  &_lg {
    padding: 8px 40px;
  }

  &:not(:disabled):not(.disabled) {

    &:hover,
    &:active,
    &:focus {
      background-color: $blue-100;
      border: 1px solid $blue-100;
    }
  }
}

.card-body {
  .tag-title {
    font-size: 16px;
  }
}

.card-tag-body {
  margin: 135px 0 150px;
  display: flex;
  justify-content: center;

  &__text {
    text-align: center;
    color: $gray;
    font-size: 14px;
    font-weight: 500;
  }
}